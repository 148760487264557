import React from "react";
import { IIcon } from "./Icon.types";
import { StyledIcon } from "./styles";
import { icons } from "./Icons";

const Icon = ({
  name,
  color,
  size,
  dataTestId,
  margin,
  styles,
  scale,
}: IIcon) => {
  const icon = icons.find(({ iconName }) => iconName === name)?.icon;
  return (
    <>
      <StyledIcon
        color={color}
        size={size}
        data-testid={dataTestId}
        margin={margin}
        styles={styles}
        scale={scale}
      >
        {icon}
      </StyledIcon>
    </>
  );
};

export default Icon;
