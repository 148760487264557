import styled from "styled-components";
import { px2Rem } from "../../utils/px2Rem";
import { IHeader } from "./Header.types";

const StyledHeaderContent = styled.span<IHeader>`
  ${({ centered }) =>
    centered &&
    `
  display: block;
  text-align: center;
  `}
  font-family: 'Raylo-Bold';
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${px2Rem(lineHeight)};`}
  & span {
    font-family: "Raylo-Bold";
    ${({ subColor }) => subColor && `color: ${subColor};`}
    ${({ subFontWeight }) => subFontWeight && `font-weight: ${subFontWeight};`}
  }
`;

export { StyledHeaderContent };
