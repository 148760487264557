import React from "react";
import { StyledCopy } from "./styles";
import { ICopy } from "./Copy.types";

import "../../typography.scss";

const Copy = ({
  children,
  color,
  fontSize,
  dataTestId,
  bold,
  italic,
  lineHeight,
  textAlign,
  verticalAlign,
  styles,
}: ICopy) => {
  return (
    <StyledCopy
      color={color}
      fontSize={fontSize}
      data-testid={dataTestId}
      bold={bold}
      italic={italic}
      lineHeight={lineHeight}
      textAlign={textAlign}
      verticalAlign={verticalAlign}
      styles={styles}
    >
      {children}
    </StyledCopy>
  );
};

export default Copy;
