// Generated with util/create-component.js
import React from "react";
import { primaryColors } from "../../colors";
import DualFontWeightCopy from "../../components/DualFontWeightCopy/DualFontWeightCopy";
import Container from "../../elements/Container";
import Icon from "../../elements/Icon";
import TextLink from "../../elements/TextLink";
import { px2Rem } from "../../utils/px2Rem";

import { ProductPageWidgetProps } from "./ProductPageWidget.types";

const ProductPageWidget: React.FC<ProductPageWidgetProps> = ({
  number,
  href,
  linkOnClick,
}) => (
  <Container flexRow height={px2Rem(70)}>
    <Container
      width="fit-content"
      padding={`${px2Rem(4)} ${px2Rem(16)} ${px2Rem(0)} ${px2Rem(12)}`}
      centerVertical
      height="100%"
    >
      <Icon name="RpBadge" scale={1.1} />
    </Container>
    <Container centerVertical height="100%" maxWidth="28ch">
      <DualFontWeightCopy fontSize={14} fontColor={primaryColors.c01}>
        Lease for <span>£{number}/mo & £0 today. </span>
        <TextLink
          regular
          fontColor={primaryColors.c01}
          href={href}
          onClick={linkOnClick}
        >
          Learn more
        </TextLink>
      </DualFontWeightCopy>
    </Container>
  </Container>
);

export default ProductPageWidget;
