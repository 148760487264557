import styled from "styled-components";
import { IStyledIcon } from "./Icon.types";

const StyledIcon = styled.div<IStyledIcon>`
  ${({ margin }) => margin && `margin: ${margin}`}
  & svg {
    color: ${({ color }) => color && color};
    width: ${({ size }) => size && size};
    height: ${({ size }) => size && size};
    transform: ${({ scale }) => scale && `scale(${scale})`};
  }
  & svg path {
    stroke: ${({ color }) => color && color};
  }
  ${({ styles }) => styles}
`;

export { StyledIcon };
