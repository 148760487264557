import * as React from "react";

const SvgRpBadge = (props) => (
  <svg
    width={56}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <rect
      x={0.595}
      y={0.595}
      width={54.811}
      height={32.811}
      rx={2.973}
      fill="#4252FF"
    />
    <g clipPath="url(#rp-badge_svg__a)" fill="#fff">
      <path d="m14.922 11.734 1.818 3.128v.156h-1.086L13.873 11.9H12.24v3.117h-1.02V11.9h.976v-.979h-.976v-3.7h3.448c1.375 0 2.406.925 2.406 2.305 0 1.38-.922 2.105-2.152 2.207zm1.097-2.17c0-.88-.531-1.359-1.518-1.359h-2.262v2.717h2.262c.987-.003 1.518-.482 1.518-1.36v.002zm8.593 5.296v.155h-1.02l-.85-2.242h-3.386l-.819 2.248h-1.02v-.156l3-7.642h1.108l2.987 7.636zm-3.481-6.48h-.134l-1.319 3.484h2.74L21.13 8.38zm9.747-.98-3.816 7.615h-1.055v-.156l1.54-2.96h-1.03L24.19 7.4v-.178h1.065l2.35 4.587 2.227-4.587h1.039l.006.178zm6.03 6.646v.969h-5.21V7.223h1.02v6.826l4.19-.003zm7.785-2.916c0 2.438-1.82 4.174-3.947 4.174-2.152 0-3.948-1.736-3.948-4.174 0-2.46 1.796-4.174 3.948-4.174 2.128 0 3.947 1.713 3.947 4.174zm-1.053 0c0-1.837-1.277-3.173-2.894-3.173-1.618 0-2.895 1.336-2.895 3.173s1.276 3.173 2.895 3.173 2.894-1.314 2.894-3.173zm-15.47 7.647-.017-.043h-1.202l-3.014 7.692v.243h1.135l.821-2.248h3.287l.853 2.248h1.136v-.236l-2.998-7.656zm-.593 1.251 1.236 3.348h-2.547l1.267-3.348h.044zm-5.797-1.293h-3.516v3.695h1.111v1.123h-1.111v3.116h1.156v-3.116h1.561l1.095-.027c1.324-.108 2.179-1.055 2.179-2.413 0-1.404-1.018-2.378-2.476-2.378zm1.285 2.405c0 .844-.502 1.29-1.452 1.29H19.42v-2.58h2.193c.95 0 1.452.446 1.452 1.29zm13.143-2.407-2.187 4.502-2.308-4.502H30.54v.264l2.268 4.39h1.13l-1.58 3.034v.24h1.164l3.833-7.65.007-.015v-.263h-1.153zm1.439 6.735h-1.2v1.194h1.2v-1.194z" />
    </g>
    <rect
      x={0.595}
      y={0.595}
      width={54.811}
      height={32.811}
      rx={2.973}
      stroke="#4252FF"
      strokeWidth={1.189}
    />
    <defs>
      <clipPath id="rp-badge_svg__a">
        <path
          fill="#fff"
          transform="translate(10.857 6.714)"
          d="M0 0h34.286v20.571H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgRpBadge;
