// Generated with util/create-component.js
import React from "react";
import styled from "styled-components";
import Copy from "../../elements/Copy";

import { DualFontWeightCopyProps } from "./DualFontWeightCopy.types";

const StyledDualFontWeightCopy = styled.div`
  & p > span {
    font-family: Raylo-Bold;
  }
`;

const DualFontWeightCopy: React.FC<DualFontWeightCopyProps> = ({
  children,
  fontSize,
  fontColor,
}) => (
  <StyledDualFontWeightCopy data-testid="DualFontWeightCopy">
    <Copy fontSize={fontSize} color={fontColor}>
      {children}
    </Copy>
  </StyledDualFontWeightCopy>
);

export default DualFontWeightCopy;
