import React from "react";
import { Link } from "react-router-dom";
import { StyledTextLink } from "./styles";
import { ITextLink } from "./TextLink.types";

import "../../typography.scss";

export const TextLink = ({
  dataTestId,
  children,
  fontSize,
  fontColor,
  onClick,
  link,
  href,
  target,
  regular,
  bold,
}: ITextLink) => {
  if (link) {
    return (
      <Link to={link}>
        <StyledTextLink
          data-testid={dataTestId}
          fontSize={fontSize}
          fontColor={fontColor}
          regular={regular}
          bold={bold}
        >
          {children}
        </StyledTextLink>
      </Link>
    );
  }
  if (href) {
    return (
      <a href={href} target={target || "_blank"} rel="noopener noreferrer">
        <StyledTextLink
          data-testid={dataTestId}
          fontSize={fontSize}
          fontColor={fontColor}
          regular={regular}
          bold={bold}
        >
          {children}
        </StyledTextLink>
      </a>
    );
  }
  return (
    <StyledTextLink
      data-testid={dataTestId}
      fontSize={fontSize}
      fontColor={fontColor}
      onClick={onClick}
      regular={regular}
      bold={bold}
    >
      {children}
    </StyledTextLink>
  );
};
