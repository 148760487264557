import styled from "styled-components";
import { px2Rem } from "../../utils/px2Rem";
import { IStyledLink } from "./TextLink.types";

const StyledTextLink = styled.span<IStyledLink>`
  font-family: Raylo-Regular;
  color: ${({ theme }) => theme?.typography?.textLink?.color};
  text-decoration: underline;
  font-size: ${px2Rem(16)};
  cursor: pointer;
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  ${({ fontColor }) => fontColor && `color: ${fontColor};`}
  ${({ regular }) => regular && "font-family: Raylo-Regular;"}
  ${({ bold }) => bold && "font-family: Raylo-Bold;"}
`;

export { StyledTextLink };
